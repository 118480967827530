import React from 'react';
import Product from './product';
import Container from 'ui-kit/container';
import styled from 'styled-components';
import { mq } from 'helpers/stylehelpers';

class Filter extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            category: '',
        };
    }

    setCategory(category) {
        this.setState({ category });
    }

    render() {
        let filteredList = this.props.edges;
        if (this.props.category !== 'all') {
            filteredList = this.props.edges.filter(
                edge => edge.node.category === this.props.category
            );
        }
        return (
            <div>
                <Container grid cols={4}>
                    {filteredList.map(edge => {
                        return (
                            <Product
                                key={edge.node.title}
                                title={edge.node.title}
                                link={edge.node.slug}
                                category={edge.node.category}
                                src={edge.node.src.childImageSharp.fluid}
                            />
                        );
                    })}
                </Container>
            </div>
        );
    }
}
export default Filter;
