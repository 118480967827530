import React, { useState, useEffect } from 'react';
import { Highlight, P } from 'ui-kit/typography';
import Intro from 'ui-kit/intro';
import Section from 'ui-kit/section';
import Img from 'gatsby-image';
import Container from 'ui-kit/container';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { primaryColor } from 'helpers/themehelpers';
import Homestage from '../components/homestage/homestage';
import Teaserbox from '../components/homestage/teaserbox';
import Header from '../components/header';
import logo from '../images/logo.png';

import Filter from '../components/filter';

import Layout from '../components/layout';

/** styles teaserbox */
const StyledTeaserbox = styled.div`
    a > div {
        border: 3px solid white;
    }

    a [class*='teaserbox__Overlay'] {
        background-color: #165d9f;
        font-family: 'Minion';
    }
`;

const IndexPage = ({ data }) => {
    const [category, setCategory] = useState('all');
    useEffect(() => {
        console.log(category);
    });

    const Styledbutton = styled.button`
        padding: 15px 20px;
        background-color: white;
        border: none;
        cursor: pointer;

        &:focus {
            outline: none;
        }
    `;

    const Activebutton = styled(Styledbutton)`
        color: #1b69b1;
        border-bottom: 3px solid ${primaryColor};
    `;

    const Filterholder = styled.div`
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
    `;
    const Filterwrapper = styled.div`
        margin: 0 auto;
        display: inline-block;
        text-align: center;
        border-radius: 10px;
        overflow: hidden;
        box-shadow: 0 1.7px 2.2px rgba(0, 0, 0, 0.011), 0 4.2px 5.3px rgba(0, 0, 0, 0.016),
            0 7.9px 10px rgba(0, 0, 0, 0.02), 0 14.1px 17.9px rgba(0, 0, 0, 0.024),
            0 26.3px 33.4px rgba(0, 0, 0, 0.029), 0 63px 80px rgba(0, 0, 0, 0.04);
        background-color: white;
    `;

    const CATEGORY_1 = 'Für heißen Genuss';
    const CATEGORY_2 = 'Bayerische Originale';
    const CATEGORY_3 = 'Leckere Brotzeit';
    const CATEGORY_4 = 'Extra feine Scheiben';

    const scrollTo1 = (elOrEvt, scrollOffset) => {
        let element;
        let offset = scrollOffset || 0;
        setCategory(CATEGORY_1);
        if (elOrEvt.currentTarget) {
            elOrEvt.preventDefault();
            elOrEvt.currentTarget.blur();
            const id = elOrEvt.currentTarget.getAttribute('href').substring(1);
            element = document.getElementById(`${id}`);
            offset = elOrEvt.currentTarget.getAttribute('data-scroll-offset');
        } else {
            element = elOrEvt;
        }

        if (element) {
            window.setTimeout(() => {
                window.scroll({
                    top: element.getBoundingClientRect().top + window.pageYOffset - offset,
                    behavior: 'smooth',
                });
            }, 0);
        }
    };
    const scrollTo2 = (elOrEvt, scrollOffset) => {
        let element;
        let offset = scrollOffset || 0;
        setCategory(CATEGORY_2);
        if (elOrEvt.currentTarget) {
            elOrEvt.preventDefault();
            elOrEvt.currentTarget.blur();
            const id = elOrEvt.currentTarget.getAttribute('href').substring(1);
            element = document.getElementById(`${id}`);
            offset = elOrEvt.currentTarget.getAttribute('data-scroll-offset');
        } else {
            element = elOrEvt;
        }

        if (element) {
            window.setTimeout(() => {
                window.scroll({
                    top: element.getBoundingClientRect().top + window.pageYOffset - offset,
                    behavior: 'smooth',
                });
            }, 0);
        }
    };
    const scrollTo3 = (elOrEvt, scrollOffset) => {
        let element;
        let offset = scrollOffset || 0;
        setCategory(CATEGORY_3);
        if (elOrEvt.currentTarget) {
            elOrEvt.preventDefault();
            elOrEvt.currentTarget.blur();
            const id = elOrEvt.currentTarget.getAttribute('href').substring(1);
            element = document.getElementById(`${id}`);
            offset = elOrEvt.currentTarget.getAttribute('data-scroll-offset');
        } else {
            element = elOrEvt;
        }

        if (element) {
            window.setTimeout(() => {
                window.scroll({
                    top: element.getBoundingClientRect().top + window.pageYOffset - offset,
                    behavior: 'smooth',
                });
            }, 0);
        }
    };
    const scrollTo4 = (elOrEvt, scrollOffset) => {
        let element;
        let offset = scrollOffset || 0;
        setCategory(CATEGORY_4);
        if (elOrEvt.currentTarget) {
            elOrEvt.preventDefault();
            elOrEvt.currentTarget.blur();
            const id = elOrEvt.currentTarget.getAttribute('href').substring(1);
            element = document.getElementById(`${id}`);
            offset = elOrEvt.currentTarget.getAttribute('data-scroll-offset');
        } else {
            element = elOrEvt;
        }

        if (element) {
            window.setTimeout(() => {
                window.scroll({
                    top: element.getBoundingClientRect().top + window.pageYOffset - offset,
                    behavior: 'smooth',
                });
            }, 0);
        }
    };

    const homestageBackgroundImage = data.homestageBackgroundImage.childImageSharp;
    const homestageTeaserImage1 = data.homestageTeaserImage1.childImageSharp;
    const homestageTeaserImage2 = data.homestageTeaserImage2.childImageSharp;
    const homestageTeaserImage3 = data.homestageTeaserImage3.childImageSharp;
    const homestageTeaserImage4 = data.homestageTeaserImage4.childImageSharp;
    const contentImage1 = data.contentImage1.childImageSharp.fluid;
    const contentImage2 = data.contentImage2.childImageSharp.fluid;
    const pageStructure = data.allPagestructureJson.edges.map(({ node }) => node);

    const Buttonall = category === 'all' ? Activebutton : Styledbutton;
    const ButtonHeiss = category === CATEGORY_1 ? Activebutton : Styledbutton;
    const ButtonBayerisch = category === CATEGORY_2 ? Activebutton : Styledbutton;
    const ButtonLecker = category === CATEGORY_3 ? Activebutton : Styledbutton;
    const ButtonExtra = category === CATEGORY_4 ? Activebutton : Styledbutton;

    const homestageCategoryTeasers = [
        <a onClick={scrollTo1} href="#produkte">
            <Teaserbox
                href="#produkte"
                key="genuss"
                title={CATEGORY_1}
                image={homestageTeaserImage1}
                imageAlt={CATEGORY_1}
                centered
                noHover
                imageCritical
            />
        </a>,
        <a onClick={scrollTo2} href="#produkte">
            <Teaserbox
                href="#produkte"
                key="originale"
                title={CATEGORY_2}
                image={homestageTeaserImage2}
                imageAlt={CATEGORY_2}
                centered
                noHover
                imageCritical
            />
        </a>,
        <a onClick={scrollTo3} href="#produkte">
            <Teaserbox
                href="#produkte"
                key="brotzeit"
                title={CATEGORY_3}
                image={homestageTeaserImage3}
                imageAlt={CATEGORY_3}
                centered
                noHover
                imageCritical
            />
        </a>,
        <a onClick={scrollTo4} href="#produkte">
            <Teaserbox
                href="#produkte"
                key="extra"
                title={CATEGORY_4}
                image={homestageTeaserImage4}
                imageAlt={CATEGORY_4}
                centered
                noHover
                imageCritical
            />
        </a>,
    ];

    return (
        <Layout
            description="Leckere Wurst von hier – so schaut’s aus!"
            title="Leckere Wurst von hier – so schaut’s aus!"
        >
            <Header
                pageStructure={pageStructure}
                logo={{
                    image: logo,
                    alt: 'Edeka Mein Bayern',
                    title: 'Edeka Mein Bayern',
                }}
            />
            <StyledTeaserbox>
                <Homestage
                    intro={{
                        title: (
                            <>
                                <Highlight font="minion" className="homestage-serif">
                                    Leckere Wurst
                                    <br />
                                    von hier –
                                    <br />
                                    so schaut’s aus!
                                </Highlight>
                            </>
                        ),
                        content: '',
                    }}
                    backgroundImage={homestageBackgroundImage}
                    sliderElements={homestageCategoryTeasers}
                />
            </StyledTeaserbox>

            <Section container="l" gap="xs" id="ueber-mein-bayern">
                <Intro title="Echte Bayern erkennt man am Geschmack!" gap="xxxl">
                    <P fontSize="m">
                        Ob fein und mild oder herzhaft-kräftig: Lassen Sie sich traditionell gute
                        Qualität aus der Heimat Bayerns schmecken! Frische und appetitlich verpackte
                        Wurstsorten stehen zu Ihrer Wahl. Darunter auch bayerische Spezialitäten wie
                        Leberkäs, Schweinsbratwürstl oder Münchner Weißwurst.
                    </P>
                </Intro>
                <Container size="l" grid layout="6-6" cols="2" alignItems="center" gap="xxxl">
                    <Img fluid={contentImage1} alt="super" />
                    <div>
                        <P>
                            <strong>So genießt man Bayern!</strong>
                            <br />
                            Frisch und lecker für jeden Geschmack. Entdecken Sie unsere beliebten
                            bayerischen Spezialitäten – in erstklassiger Qualität für echte
                            Genießer. Zahlreiche weitere leckere Wurstsorten aus dem EDEKA mein
                            Bayern Programm entdecken Sie bei EDEKA Südbayern.
                        </P>
                        <P>Guten Appetit!</P>
                    </div>
                </Container>
                <Container size="l" grid layout="6-6" cols="2" alignItems="center">
                    <div>
                        <P>
                            Wichtig zu wissen: Auf diese leckeren Produkte müssen Allergiker nicht
                            verzichten. Entdecken Sie die große Wurstvielfalt für Ihren
                            unbeschwerten Genuss:
                        </P>
                        <p>
                            <strong>100% gluten- und laktosefrei!*</strong>
                        </p>
                        <p>
                            Damit bietet Ihnen mein Bayern ein Sortiment ganz nach Ihrem Geschmack!
                            So haben Sie Sicherheit und nur noch die Qual der Wahl.
                        </p>
                        <P>* Sorten mit Käse sind gluten-, aber nicht laktosefrei</P>
                    </div>
                    <Img fluid={contentImage2} alt="super" />
                </Container>
            </Section>
            <Section container="l" gap="xxl" bg="gray" id="produkte">
                <Intro title="Produkte" gap="xl" />
                <Filterholder>
                    <Filterwrapper>
                        <Buttonall onClick={evt => setCategory('all')}>Unsere Produkte</Buttonall>
                        <ButtonHeiss onClick={evt => setCategory(CATEGORY_1)}>
                            Für heißen Genuss
                        </ButtonHeiss>
                        <ButtonBayerisch onClick={evt => setCategory(CATEGORY_2)}>
                            Bayerische Originale
                        </ButtonBayerisch>
                        <ButtonLecker onClick={evt => setCategory(CATEGORY_3)}>
                            Leckere Brotzeit
                        </ButtonLecker>
                        <ButtonExtra onClick={evt => setCategory(CATEGORY_4)}>
                            Extra feine Scheiben
                        </ButtonExtra>
                    </Filterwrapper>
                </Filterholder>
                <Filter category={category} edges={data.allProductsJson.edges} />
            </Section>
        </Layout>
    );
};

export const query = graphql`
    query {
        homestageBackgroundImage: file(relativePath: { eq: "header/start.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        homestageTeaserImage1: file(relativePath: { eq: "index-page/stage-genuss.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 360, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        homestageTeaserImage2: file(relativePath: { eq: "index-page/stage-original.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 360, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        homestageTeaserImage3: file(relativePath: { eq: "index-page/stage-brotzeit.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 360, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        homestageTeaserImage4: file(relativePath: { eq: "index-page/stage-scheiben.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 360, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        contentImage1: file(relativePath: { eq: "index-page/intro1.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 605, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        contentImage2: file(relativePath: { eq: "index-page/intro2.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 605, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        allPagestructureJson {
            edges {
                node {
                    url
                    title
                }
            }
        }
        allProductsJson {
            edges {
                node {
                    title
                    slug
                    category
                    src {
                        childImageSharp {
                            fluid(maxWidth: 250, quality: 80) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default IndexPage;
