import React from 'react';
import { Link } from 'gatsby';
import { mq } from 'helpers/stylehelpers';
import Img from 'gatsby-image';
import styled from 'styled-components';
import Bg from '../images/index-page/product-bg.jpg';

class Product extends React.Component {
    render() {
        const Imgwrapper = styled.div`
            max-width: 130px;
            margin: 0 auto;
            padding: 20px 0px 35px 0px;
        `;

        const Imgholder = styled.div`
            background-color: white;
            position: relative;
            background-size: cover;
        `;

        const Productwrapper = styled.div`
            text-align: center;
            box-shadow: 0 0.7px 1.2px rgba(0, 0, 0, 0.011), 0 1.6px 2.9px rgba(0, 0, 0, 0.016),
                0 3px 5.4px rgba(0, 0, 0, 0.02), 0 5.4px 9.6px rgba(0, 0, 0, 0.024),
                0 10px 18px rgba(0, 0, 0, 0.029), 0 24px 43px rgba(0, 0, 0, 0.04);

            background-color: white;
            margin-bottom: 20px;
            border-radius: 5px;
            overflow: hidden;

            ${mq.medium`
            color: white;
            margin-bottom: 0px;
            `};
        `;

        const Productheadline = styled.h2`
            background-color: #e5e6e8;
            font-family: 'MinionPro', 'Times New Roman', serif;
            margin: 0;
            padding: 20px 0;
            color: #13518a;
            font-weight: 400;
        `;

        const StyledLink = styled(Link)`
            text-decoration: none;
        `;
        const Category = styled.p`
            position: absolute;
            bottom: -15px;
            left: 50%;
            transform: translateX(-50%);
            background-color: #13518a;
            display: inline-block;
            padding: 5px 10px;
            color: white;
            border-radius: 15px;
            margin: 0;
            display: inline-block;
            white-space: nowrap;
            font-size: 13px;
        `;

        return (
            <StyledLink to={this.props.link}>
                <Productwrapper>
                    <Imgholder>
                        <Imgwrapper>
                            <Img fluid={this.props.src} alt="Mein Bayern Produkt" />
                        </Imgwrapper>
                        <Category> {this.props.category} </Category>
                    </Imgholder>
                    <Productheadline> {this.props.title} </Productheadline>
                </Productwrapper>
            </StyledLink>
        );
    }
}
export default Product;
