import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Image from 'gatsby-image';
import { rem, rgba, position } from 'polished';
import theme from 'styled-theming';

import { colors, fontSizes } from 'helpers/variables';
import { layerable, gapable } from 'helpers/traits';
import { fluidImageType } from 'helpers/prop-types';

import LinkWrapper from './link-wrapper';
import Icon, { icons } from 'ui-kit/icon';

export const primaryColorAlpha = theme('main', {
    sbf: rgba(colors.sbf.primary, 0.8),
    meinBayern: rgba(colors.meinBayern.secondary, 0.85),
});

/** Hover Content */
const HoverContent = styled.div`
    ${position('absolute', 0, 0, 0, 0)};
    align-items: center;
    background-color: ${rgba(colors.white, 0.9)};
    display: flex;
    justify-content: center;
    opacity: 0;
    padding: ${rem(20)} ${rem(30)};
    transition: opacity 150ms;
    z-index: 1;
`;

/** Teaserbox-Wrapper */
const Wrapper = styled.div`
    color: inherit;
    display: flex;
    overflow: hidden;
    position: relative;
    transition: transform 0.25s;
    border-radius: 5px;
    ${layerable()};
    ${gapable()};

    &:hover {
        ${({ specialHover }) => !specialHover && `transform: scale(1.025);`};

        & > ${HoverContent} {
            opacity: 1;
        }
    }
`;

/** Overlay */
const Overlay = styled.div`
    background-color: ${primaryColorAlpha};
    color: ${colors.white};
    padding: ${rem(10)} ${rem(20)};
    ${position('absolute', 'auto', 0, 0, 0)};

    ${({ centered }) => (centered ? 'text-align: center' : null)};
    ${({ withIcon }) => (withIcon ? `padding-right: ${rem(50)}` : null)};
`;

/** Title */
const Title = styled.strong`
    ${fontSizes.m};
    font-weight: normal;
`;

/** Text */
const Text = styled.p`
    ${fontSizes.s};
    margin: 0;
`;

/** Icon */
const TeaserboxIcon = styled(Icon)`
    ${position('absolute', '50%', rem(20), 'auto', 'auto')};
    fill: ${colors.white};
    font-size: ${rem(35)}; /* Icon-Size (1em) */
    transform: translateY(-50%);
`;

const imageStyle = `
    height: 100%;
    width: 100%;
`;

/** Wenn Teaserbild als SVG eingesetzt wird */
const SimpleImage = styled.img`
    ${imageStyle};
`;

/** Gestyltes Gatsby-Image */
const StyledImage = styled(Image)`
    ${imageStyle};
`;

/**
 * Erzeugt eine Teaserbox
 *
 * @param {string} props.gap Optional: Der Abstand nach unten ('s', 'm', 'l', 'xl', 'xxl', 'xxxl')
 * @param {object} props.hoverContent Optional: der Inhalt des Teasers bei hover
 * @param {string} props.href Das Link-Ziel
 * @param {string} props.icon Optional: das Icon (rechts)
 * @param {object} props.image Das Bild ((fluid) childImageSharp Object)
 * @param {string} props.imageAlt Optional: Alt-Text für das Bild
 * @param {number} props.layer Optional: Die Ebene/Größe des Schattens
 * @param {string} props.text Optional: der Text/Subtitel
 * @param {string} props.title Optional: der Titel
 * @param {string} props.svgImage Optional: Flag, ob Bild SVG ist
 *
 * @example <Teaserbox image={...} title="Rezepte" text="Suchen Sie sich etwas leckeres aus!" icon="caretRight" />
 */
const Teaserbox = ({
    image,
    imageAlt,
    title,
    text,
    icon,
    href,
    hoverContent,
    centered,
    layer,
    gap,
    imageCritical,
    noHover,
}) => (
    <Wrapper to={href} layer={layer} gap={gap} specialHover={!!hoverContent || noHover}>
        {typeof image === 'string' ? (
            <SimpleImage src={image} alt={imageAlt} />
        ) : (
            <StyledImage
                fluid={image.fluid}
                critical={imageCritical}
                fadeIn={!imageCritical}
                alt={imageAlt}
            />
        )}
        {title || text || icon ? (
            <Overlay withIcon={!!icon} centered={centered}>
                {title ? <Title>{title}</Title> : null}
                {text ? <Text>{text}</Text> : null}
                {icon ? <TeaserboxIcon type={icon} /> : null}
            </Overlay>
        ) : null}
        {hoverContent ? <HoverContent>{hoverContent}</HoverContent> : null}
    </Wrapper>
);

Teaserbox.propTypes = {
    centered: PropTypes.bool,
    gap: gapable.propType,
    hoverContent: PropTypes.node,
    href: PropTypes.string.isRequired,
    icon: PropTypes.oneOf(Object.keys(icons)),
    image: PropTypes.oneOfType([fluidImageType, PropTypes.string]).isRequired,
    imageAlt: PropTypes.string,
    layer: layerable.propType,
    text: PropTypes.string,
    title: PropTypes.string,
    imageCritical: PropTypes.bool,
    noHover: PropTypes.bool,
};

Teaserbox.defaultProps = {
    centered: false,
    gap: null,
    hoverContent: null,
    imageAlt: null,
    icon: null,
    layer: null,
    text: null,
    title: null,
    imageCritical: null,
    noHover: false,
};

export default Teaserbox;
