import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { position, rgba, rem } from 'polished';
import theme from 'styled-theming';

import { primaryColor } from 'helpers/themehelpers';

import { layerable } from 'helpers/traits';
import { colors, fontSizes } from 'helpers/variables';
import { mq, boxShadow } from 'helpers/stylehelpers';
import { fluidImageType } from 'helpers/prop-types';

import Tag from './tag';
import { Headline } from './typography';

const tagColor = theme('main', {
    sbf: colors.sbf.primary,
    vielLeicht: colors.vielLeicht.secondary,
});

/** Wrapper */
const Wrapper = styled.div`
    position: relative;
`;

/** Wrapper um die Komponente */
const Content = styled.div`
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    ${({ pullChildrenUp }) =>
        pullChildrenUp &&
        `
        > * {
            width: 100%;
        }
    `};

    ${mq.smallOnly`
        ${({ pullChildrenUp }) => !pullChildrenUp && `margin-top: -2.5em`};
    `};

    ${mq.medium`
        ${({ pullChildrenUp }) =>
            !pullChildrenUp &&
            css`
                padding: 0 1em;
                ${position('absolute', null, null, '8%', '0')};
            `};
    `};

    ${mq.large`
        ${({ pullChildrenUp }) =>
            pullChildrenUp &&
            css`
                margin-top: 0;
                > * {
                    width: auto;
                    max-width: ${rem(600)};
                }
                ${position('absolute', null, null, '15%', '0')};
            `};
    `};
`;

/** Hintergrundbild */
const Image = styled(Img)`
    width: 100%;
    ${mq.medium`
        ${({ bottomShade }) =>
            bottomShade &&
            css`
                &:before {
                    ${position('absolute', null, null, '0', '0')};
                    background: linear-gradient(
                        180deg,
                        ${rgba(colors.black, 0)} 70%,
                        ${rgba(colors.black, 0.25)} 85%,
                        ${rgba(colors.black, 0.5)} 100%
                    );
                    content: '';
                    height: 100%;
                    width: 100%;
                    z-index: 1;
                }
            `};
    `};
`;

/** Angepasster Tag */
const StyledTag = styled(Tag)`
    color: ${tagColor};
    background-color: ${colors.white};
    ${mq.smallOnly`
        background-color: ${rgba(colors.white, 0.8)};
        border-radius: 0;
        width: 100%;
    `};

    ${mq.medium`
        ${boxShadow()};
        ${layerable(2)};
    `};
`;

/** Headline (ohne Level) */
const Title = styled(Headline)`
    ${fontSizes.h1}
    color: ${colors.white};
    margin: 0;
    text-align: center;
    width: 100%;

    ${mq.smallOnly`
        background-color: ${primaryColor};
        padding: 0.25em 0.5em;
    `};

    ${mq.medium`
        background-color: transparent;
        text-shadow: 0 0 0.5em ${rgba(colors.black, 0.25)};
    `};
`;

/**
 * Stellt die Bühne für die meisten der Content-Seiten bereit
 * @param {string} props.className Optional: ClassName für erweitertes Styling
 * @param {object} props.image Das Bild im Hintergrund
 * @param {string} props.imageAlt Optional: Alt-Text für das Bild
 * @param {object} props.headline Optional: Headline
 *     @param {string} props.headline.title Title in der Headline
 *     @param {string} props.headline.level Level der Headline (nur semantisch, Größe bleibt gleich)
 * @param {string} props.tag Optional: Text im Tag
 * @param {boolean} props.bottomShade Optional: Schatten von unten im Bild
 * @param {node} props.children Optional: Kindelemente
 * @example
 *      <Stage image={bgImage} headline={{text: 'Titel', level: 'h2'}} tag="Mein Tag" />
 */
const Stage = ({ className, image, imageAlt, headline, tag, bottomShade, children }) => {
    const content = (
        <>
            {(tag || headline) && !children ? (
                <Content>
                    {tag && <StyledTag size="s">{tag}</StyledTag>}
                    {headline && <Title level={headline.level}>{headline.text}</Title>}
                </Content>
            ) : (
                <>{children && <Content pullChildrenUp>{children}</Content>}</>
            )}
        </>
    );
    return (
        <Wrapper className={className}>
            <Image fluid={image.fluid} bottomShade={bottomShade} alt={imageAlt} />
            {content}
        </Wrapper>
    );
};

/** Props */
Stage.propTypes = {
    className: PropTypes.string,
    image: fluidImageType.isRequired,
    imageAlt: PropTypes.string,
    headline: PropTypes.shape({
        text: PropTypes.string,
        level: PropTypes.string,
    }),
    tag: PropTypes.string,
    children: PropTypes.node,
    bottomShade: PropTypes.bool,
};

/** default Props */
Stage.defaultProps = {
    className: null,
    headline: null,
    imageAlt: null,
    tag: null,
    children: null,
    bottomShade: null,
};

export default Stage;
