import PropTypes from 'prop-types';

// Bild für das Keyvisual auf der Startseite
export const keyvisualImageType = PropTypes.shape({
    relativePath: PropTypes.string.isRequired,
    publicURL: PropTypes.string.isRequired,
    childImageSharp: PropTypes.shape({
        original: PropTypes.shape({
            width: PropTypes.number,
            height: PropTypes.number,
        }),
        fluid: PropTypes.shape({
            src: PropTypes.string,
            srcSet: PropTypes.string,
        }),
    }),
});

// Bild, dass mit dem **GatsbyImageSharpFluid_withWebp_tracedSVG** Fragment erzeugt wurde
export const fluidImage = {
    childImageSharp: PropTypes.shape({
        fluid: PropTypes.shape({
            tracedSVG: PropTypes.string,
            aspectRatio: PropTypes.number,
            src: PropTypes.string,
            srcSet: PropTypes.string,
            srcWebp: PropTypes.string,
            srcSetWebp: PropTypes.string,
            sizes: PropTypes.string,
        }),
    }),
};

export const fluidImageType = PropTypes.shape(fluidImage);

/**
 * Erzeugt ein Schema für "all*" Queries
 * @param {PropType} type PropType des node Feldes
 * @example
 * nodesOf(PropTypes.string);
 *
 * ->
 *
 * PropTypes.shape({
 *    edges: PropTypes.arrayOf(
 *         PropTypes.shape({
 *             node: PropTypes.string,
 *         })
 *     ),
 * });
 */
export function nodesOf(type) {
    return PropTypes.shape({
        edges: PropTypes.arrayOf(
            PropTypes.shape({
                node: type,
            })
        ),
    });
}

/**
 * Rezept-PropType
 */
export const recipeType = PropTypes.shape({
    id: PropTypes.string,
    slug: PropTypes.string,
    title: PropTypes.string,
    image: PropTypes.shape({
        fluid: PropTypes.object,
    }),
    category: PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
    }),
    time: PropTypes.number,
    difficulty: PropTypes.string,
    portions: PropTypes.number,
    products: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            title: PropTypes.string,
            image: PropTypes.shape({
                fluid: PropTypes.object,
            }),
        })
    ),
    ingredients: PropTypes.shape({
        childMarkdownRemark: PropTypes.shape({
            html: PropTypes.string,
        }),
    }),
    ingredientsRaw: PropTypes.string,
    preparation: PropTypes.arrayOf(
        PropTypes.shape({
            model: PropTypes.shape({
                type: PropTypes.string,
            }),
            image: PropTypes.object,
            title: PropTypes.string,
            description: PropTypes.shape({
                childMarkdownRemark: PropTypes.shape({
                    html: PropTypes.string,
                }),
            }),
            descriptionRaw: PropTypes.string,
            video: PropTypes.object,
        })
    ),
});

// Struktur der Daten für die Händlersuche
export const storeType = PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    street: PropTypes.string,
    zip: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
});
